<template>
  <div id="app" :class="active?'statistics':'other'">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
  	return{
      active:false,
	  }
  },
  methods:{
    statistics(){
		  this.active=true
	  },
  },
  watch:{
    $route(to,from){
      // console.log(to.path)
      if(to.path=='/Statistics'){
        this.statistics()
      }else{
         this.active=false
      }
    }
  }
}
</script>

<style lang="less" scoped>
    .statistics{
      background-color: #F6F7FB;
    }
    .other{
      background-color: #ffffff;
    }
</style>
