// 本地环境
// const loginURL ='https://login.ldydtech.com/?notify_url=http://192.168.31.112:8080/&app_id=C64W749F28';
// const loginURL ='http://192.168.31.61:8081/?notify_url=http://192.168.31.61:8080/&app_id=C64W749F28';
// const baseURL = 'https://wechat-dev.vnla.cn/'
// const baseURL = 'http://192.168.10.41:8001/'
// 测试环境
// const baseURL = 'https://c-dev.ldydtech.com/'
// const baseURL = 'http://192.168.31.136:8001/'//文达
// const baseURL = 'http://192.168.31.63:8001/'//龙

// var loginURL ='https://login.ldydtech.com/?notify_url=http://192.168.31.61:8080/&app_id=C64W749F28'

// 线上环境
var loginURL ='https://login.ldydtech.com/?notify_url=https://admin.ldydtech.com/&app_id=C64W749F28'
const baseURL = 'https://c.ldydtech.com/'
const wechatURL ='https://wechat.ldydtech.com/'

//写cookies
const setCookie = function (name,value){
    // var Days = 30;
    // var exp = new Date();
    // exp.setTime(exp.getTime() + Days*24*60*60*1000);
    // document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
    document.cookie = name + "="+ escape (value);
};
//读取cookies
const getCookie = function (name){
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
    if(arr=document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
};
//删除cookies
const delCookie = function (name){
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval=getCookie(name);
    if(cval!=null)
    document.cookie= name + "="+cval+";expires="+exp.toGMTString();
}

export default {  
    loginURL,
    baseURL,
    wechatURL,
    setCookie,
    getCookie,
    delCookie
}