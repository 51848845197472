import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueClipboard from 'vue-clipboard2'// 粘贴复制功能插件VueClipboard
Vue.use(VueClipboard)
import './assets/css/global.css'// 引用全局样式
import './assets/css/iconfont.css'// 引用iconfont图标
import NProgress  from 'nprogress'// 导入NProgress包对应的js
import 'nprogress/nprogress.css'// 导入NProgress包对应的css
import axios from 'axios'// 引用axios
import global from './assets/js/global.js'
axios.defaults.baseURL = global.baseURL
Vue.prototype.$global = global
// 请求头配置
axios.interceptors.request.use(config => {
  NProgress.start() // 在request拦截器中展示进度条 NProgress.start()
  config.headers.Authorization = global.getCookie('ctoken')
  
  return config // 在最后必须 return config
})
axios.interceptors.response.use(config =>{
  NProgress.done() // 在response拦截器中，隐藏进度条 NProgress.done()
  if(config.data.status==403){
    //清空sessionStorage
    global.delCookie('ctoken')
    global.delCookie('loginData')
    global.delCookie('activePath')
    // window.sessionStorage.clear()
    //跳转到登录页
    window.location.href = global.loginURL;
  }
  return config
})
//全局定义一个格式化时间的过滤器  dateFormat
Vue.filter('dateFormat', function(originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})


Vue.config.productionTip = false
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
