import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import global from '../assets/js/global.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    // redirect:'/Statistics',
    component: () => import('../views/Home.vue'),
    children:[
      //总览
      {
        path:'/Statistics',
        component:() => import('../views/Statistics/Statistics.vue')
      },
      //用户管理
      {
        path:'/UserManagement',
        component:() => import('../views/UserManagement/UserManagement.vue')
      },
      //用户详情
      {
        path:'/user/:id',
        component:() => import('../views/UserManagement/user.vue')
      },
      //授权公众号
      {
        path:'/officialAccountsOrApplet',
        component:() => import('../views/officialAccounts/officialAccountsOrApplet.vue')
      },
      //公众号管理
      {
        path:'/publicAccountManagement',
        component:() => import('../views/officialAccounts/publicAccountManagement.vue')
      },
      // 关键词回复列表
      {
        path:'/replyList',
        component:() => import('../views/officialAccounts/replyList.vue')
      },
      // 新增关键词回复
      {
        path:'/newReply',
        component:() => import('../views/officialAccounts/newReply.vue')
      },
      // 自定义菜单
      {
        path:'/customizeTheMenuBar',
        component:() => import('../views/officialAccounts/customizeTheMenuBar.vue')
      },
      // 关注回复列表
      {
        path:'/payAttentionToReplyList',
        component:() => import('../views/officialAccounts/payAttentionToReplyList.vue')
      },
      // 新增关注回复
      {
        path:'/payAttentionToReply',
        component:() => import('../views/officialAccounts/payAttentionToReply.vue')
      },
      // 消息推送列表
      {
        path:'/notificationPushList',
        component:() => import('../views/officialAccounts/notificationPushList.vue')
      },
      // 新增消息推送
      {
        path:'/newMessage',
        component:() => import('../views/officialAccounts/newMessage.vue')
      },
      //在线点餐订单管理
      {
        path:'/onlineOrderManagement',
        component:() => import('../views/OrderManagement/onlineOrderManagement.vue')
      },
      //卡券订单管理
      {
        path:'/cardOrderManagement',
        component:() => import('../views/OrderManagement/cardOrderManagement.vue')
      },
      //直充订单管理
      {
        path:'/rechargeOrderManagement',
        component:() => import('../views/OrderManagement/rechargeOrderManagement.vue')
      },
      //美团订单管理
      {
        path:'/mtOrderManagement',
        component:() => import('../views/OrderManagement/mtOrderManagement.vue')
      },
      //饿了么订单管理
      {
        path:'/elmOrderManagement',
        component:() => import('../views/OrderManagement/elmOrderManagement.vue')
      },
      //卡券对账
      {
        path:'/cardOrderVerify',
        component:() => import('../views/FinancingManagement/cardOrderVerify.vue')
      },
      //美团对账
      {
        path:'/MeituanCPS',
        component:() => import('../views/FinancingManagement/MeituanCPS.vue')
      },
      //饿了么对账
      {
        path:'/ElemeCPS',
        component:() => import('../views/FinancingManagement/ElemeCPS.vue')
      },
      //在线点餐对账
      {
        path:'/onlineOrderVerifu',
        component:() => import('../views/FinancingManagement/onlineOrderVerifu.vue')
      },
      //直充对账
      {
        path:'/rechargeOrderVerify',
        component:() => import('../views/FinancingManagement/rechargeOrderVerify.vue')
      },
      //对账详情
      {
        path:'/orderVerifyItem',
        component:() => import('../views/FinancingManagement/orderVerifyItem.vue')
      },
      //cps类型的对账详情
      {
        path:'/orderVerifyItem1',
        component:() => import('../views/FinancingManagement/orderVerifyItem1.vue')
      },
      //账号设置
      {
        path:'/AccountSetup',
        component:() => import('../views/AccountSetup/AccountSetup.vue')
      },
    ]
  },
  {
    path:'/',
    component:() => import('../views/token.vue')
  },
  { 
    path:'*',
    component:() => import('../views/mistake.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 挂载路由导航守卫
router.beforeEach((to,from,next)=>{
  if (to.path==='/') return next()
  // 获取token
  const tokenStr = global.getCookie('ctoken')
  if(!tokenStr)return next(window.location.href = global.loginURL)
  next()
})

export default router
